import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import shape from "../../../src/img/Testimonial/shape-bg.png";
import cc from "../../images/cc/cc.png"
import pgrms from "../../images/pgrms/pgrms.png"
import quiz from "../../images/quiz-portal/quiz.png"
import "./Projects.css";






export default function Projects(props) {
    let fadeInScreenHandler = (screen) => {
        if (screen.fadeInScreen !== props.id) return;
        Animations.animations.fadeInScreen(props.id);
    };
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);


    const options = {
        loop: true,
        margin: 0,
        nav: true,
        animateIn: "bounceInRight",
        animateOut: "bounceOutRight",
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1000: {
                items: 3,
            },
        },
    };

    return (
        <div>
            {/* <ScreenHeading
                title={"Projects"}
                subHeading={"Things I've Built"}
            /> */}
            <div>

                <section className="fade-in" id={props.id || ""}>
                    <ScreenHeading
                        title={"Projects"}
                        subHeading={"Things I've Built"}
                    />
                    <div className="testimonial-section">
                        
                    
                    <div className="container ">
                        <div className="row">
                            <OwlCarousel
                                className="owl-carousel"
                                id="testimonial-carousel"
                                {...options}
                            >
                                {/*  card 1  */}
                                <div className="col-lg-12">
                                    <div className="testi-item">
                                        <div className="testi-comment">
                                            <p>
                                                <i className="fa fa-quote-left" />
                                                <img src={cc} alt="Phot0 not responding" />
                                                <i className="fa fa-quote-right" />
                                            </p>

                                        </div>
                                        <div className="client-info">
                                            <h5>Cricket Club</h5>
                                            <p><button className="btn highlighted-btn">Github Repo</button></p>
                                        </div>
                                    </div>
                                </div>

                                {/* card 2 */}
                                <div className="col-lg-12">
                                    <div className="testi-item">
                                        <div className="testi-comment">
                                            <p>
                                                <i className="fa fa-quote-left" />
                                                <img src={pgrms} alt="Phot0 not responding" />
                                                <i className="fa fa-quote-right" />
                                            </p>
                                        </div>
                                        <div className="client-info">
                                            <h5>PG Rental Management System</h5>
                                            <p><button className="btn highlighted-btn">Github Repo</button></p>
                                        </div>
                                    </div>
                                </div>

                                {/* card 3 */}
                                <div className="col-lg-12">
                                    <div className="testi-item">
                                        <div className="testi-comment">
                                            <p>
                                                <i className="fa fa-quote-left" />
                                                <img src={quiz} alt="Phot0 not responding" />
                                                <i className="fa fa-quote-right" />
                                            </p>
                                        </div>
                                        <div className="client-info">
                                            <h5>Quiz Portal </h5>
                                            <p><button className="btn highlighted-btn">Github Repo</button></p>
                                        </div>
                                    </div>
                                </div>

                            </OwlCarousel>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
            <div className="footer-image">
                <img src={shape} alt="Phot0 not responding" />
            </div>
        </div>


    );
}
